import React, { useState, useEffect, useRef } from "react";
import "./CampaignCard.css";
import InputBox from "../InputBox/InputBox";
import Button from "../button/Button";
import { isValidArray, isValidObject } from "../../utils/validators";
import { dateAndTimeConverter } from "../../utils/constants";

export default function CampaignCard(props) {
  const [sumOfValues, setSumOfValues] = useState({ mrp: 0, cost: "0.00" });
  const [campaignId, setCampaignId] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [read, setRead] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    userName: "",
    phoneNumber: "",
    confirmPhoneNumber: ""
  });

  useEffect(() => {
    if (props.clearData === true) {
      setSumOfValues({ mrp: 0, cost: "0.00" });
      setCampaignId("");
      setSelectedSpecialty([]);
      setSelectedIndex({});
      setSelectedData({});
      setRegisterFormData({
        userName: "",
        phoneNumber: "",
        confirmPhoneNumber: ""
      });
      props.clearDataNew(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearData]);

  useEffect(() => {
    if (isValidObject(selectedData)) {
      let summedValue = 0;
      Object.values(selectedData).forEach((selectedValue) => {
        if (selectedValue !== undefined && selectedValue.data.mrp !== undefined) {
          summedValue = summedValue + selectedValue.data.mrp;
        }
      });

      const discountPercentage = props.cardData.discountPercentage;
      const flatDiscount = props.cardData.discountFlat;

      if (summedValue !== undefined) {
        // if both are present
        if (discountPercentage === 0 && flatDiscount === 0) {
          setSumOfValues({ mrp: 0, cost: summedValue.toString() });
        } else if (discountPercentage !== 0 && flatDiscount !== 0) {
          if (summedValue === 0) {
            setSumOfValues({ mrp: 0, cost: "0.00" });
          } else {
            const discount = (discountPercentage / 100) * summedValue;
            const bothDiscount = summedValue - discount - flatDiscount;
            const bothDiscountedAmount = bothDiscount;
            if (bothDiscountedAmount > -1) {
              setSumOfValues({ mrp: summedValue.toString(), cost: bothDiscountedAmount.toFixed(2).toString() });
            }
          }
        } else {
          // if discount present
          if (discountPercentage !== 0) {
            const discount = (discountPercentage / 100) * summedValue;
            const discountedAmount = summedValue - discount;
            if (discountedAmount > -1) {
              setSumOfValues({ mrp: summedValue.toString(), cost: discountedAmount.toFixed(2).toString() });
            } else {
              setSumOfValues({ mrp: 0, cost: "0.00" });
            }
          } else if (flatDiscount !== 0) {
            const flat = summedValue - flatDiscount;
            const flatDiscountedAmount = flat;
            if (flatDiscountedAmount > -1) {
              setSumOfValues({ mrp: summedValue.toString(), cost: flatDiscountedAmount.toFixed(2).toString() });
            } else {
              setSumOfValues({ mrp: 0, cost: "0.00" });
            }
          } else {
            setSumOfValues({ mrp: 0, cost: "0.00" });
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  const handleSelectedCard = (data) => {
    let selectedCardIndex = { ...selectedIndex };
    let tempData = { ...selectedData };
    let specialtyData = [...selectedSpecialty];
    if (selectedCardIndex[data.index] === data.index) {
      selectedCardIndex[data.index] = undefined;
      tempData[data.index] = undefined;
      specialtyData.pop(data.data.name);
    } else {
      selectedCardIndex[data.index] = data.index;
      tempData[data.index] = data;
      specialtyData.push(data.data.name);
    }
    setSelectedIndex(selectedCardIndex);
    setSelectedData(tempData);
    setSelectedSpecialty(specialtyData);
    if (typeof data.campaignId === "string") {
      setCampaignId(data.campaignId);
    }
  };

  const registerFormOnChange = (event) => {
    setRegisterFormData({ ...registerFormData, [event.target.name]: event.target.value });
  };

  const registerOnsubmit = (event) => {
    event.preventDefault();
    if (registerFormData) {
      if (typeof campaignId === "string" && isValidArray(selectedSpecialty)) {
        const data = {
          campaignId: campaignId,
          selectedSpecialty: selectedSpecialty,
          userName: registerFormData.userName,
          phoneNumber: registerFormData.phoneNumber
        };
        props.createOrder(data);
      }
    }
  };

  const redirectToMaps = () => {
    window.location.href = "https://www.google.com/maps/place/Apollo+Clinic+Madipakkam/@12.9636947,80.1875591,15z/data=!4m5!3m4!1s0x0:0x42d5003d620f5d92!8m2!3d12.9636947!4d80.1875591";
  }

  return (
    <>
      <div className="background-white outline-plain adaptive-border-radius inherit-parent-width padding-left-large padding-right-large">
        <div className="border-bottom">
          <CampaignInfoCard
            redirectToMaps={redirectToMaps}
            title={props.title}
            description={props.description}
            startsAtDate={dateAndTimeConverter(props.startsAt, "firstDate")}
            endsAtDate={dateAndTimeConverter(props.endsAt, "secondDate")}
            startsAtTime={dateAndTimeConverter(props.startsAt, "Time")}
            endsAtTime={dateAndTimeConverter(props.endsAt, "Time")}
            windowInnerWidth={props.windowInnerWidth}
            readOnClick={() => {
              setRead((prevState) => !prevState);
            }}
            readMore={read}
          />
        </div>
        <div className="padding-bottom-larger padding-top-larger adaptive-flex-direction inherit-parent-width">
          <div className="padding-top-large web-width-60-percent web-padding-right-larger padding-bottom-larger text-align-center flex-justify-content-space-evenly adaptive-display-gird ">
            <SelectingCards
              cardData={props.cardData}
              selectedIndex={selectedIndex}
              clearData={props.clearData}
              clearDataNew={props.clearDataNew}
              selectedCardOnClick={(data) => {
                handleSelectedCard(data);
              }}
            />
          </div>
          <div className="web-width-40-percent web-padding-left-larger adaptive-border-registrationForm adaptive-padding-registrationForm">
            <RegistrationFrom
              amount={sumOfValues}
              campaignId={campaignId}
              selectedSpecialty={selectedSpecialty}
              registerFormData={registerFormData}
              formOnChange={(event) => {
                registerFormOnChange(event);
              }}
              formOnSubmit={(event) => {
                registerOnsubmit(event);
              }}
              message={props.message}
              type={props.type}
              registerButtonLoading={props.registerButtonLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const CampaignInfoCard = (props) => {
  const lineCountRef = useRef(null);

  return (
    <>
      <div style={{justifyContent:"space-between", width:"100%"}} className="display-flex padding-bottom-default flex-direction-column-mobile">
        <div className="margin-top-larger padding-left-larger remove-padding-for-mobile">
          <div style={{fontSize: "20px"}} className="font-family-gilroy-medium padding-top-larger font-size-large font-color-secondary" data-cy="campaign-card-title">
            {props.title}
          </div>
          <div className=" adaptive-flex-direction-column-row-web">
            <span style={{fontWeight:"600"}} className="font-color-secondary padding-top-default font-size-medium">{`${props.startsAtDate} - ${props.endsAtDate}`}</span>
          </div>
          <div className=" adaptive-flex-direction-column-row-web">
            <span style={{fontWeight:"600"}} className="font-color-secondary padding-top-default font-size-medium">Only limited slots available</span>
          </div>
            <div>
              <div
                className={`sample-true padding-top-large flex-direction-column line-height-20px work-spacing-1px padding-bottom-large font-size-large`}
                data-cy="campaign-card-web-description"
                ref={lineCountRef}
              >
                <ul>
                  {props.description && props.description.split(".").filter(point => !!point).map((point,index) => {
                    return (
                        <li key={index}>{point}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

const SelectingCards = (props) => {
  const currentDate = +new Date();
  return (
    props.cardData.specialties &&
    Object.values(props.cardData.specialties) &&
    Object.values(props.cardData.specialties).map((data, index) => {return (
      <div
        className=" flex-center-children-horizontally flex-center-children-vertically user-select-none adaptive-max-width-height margin-bottom-large "
        key={index}
      >
        <label className={` ${data.status === "FULL" ? "outline-dashed" : "outline-plain"} ${
              props.selectedIndex[index] === index
                ? "selected-border font-family-gilroy-regular font-weight font-color-primary"
                : "white-border font-family-gilroy-regular "
            } border-radius-default flex-center-children-vertically cursor-pointer user-select-none `}
            style={{backgroundColor:`${props.selectedIndex[index] === index ? "#1c6ea4" : "white"}`, color:`${props.selectedIndex[index] === index ? "white" : "#4e4e4e"}`}}>
              <input type={"checkbox"} style={{marginLeft:"8px"}} disabled={data.status === "FULL" || data.endsAt <= currentDate}
                onChange={(event) => {
                  if (data.status !== "FULL" && data.endsAt >= currentDate) {
                    props.selectedCardOnClick({ data: data, index: index, campaignId: props.cardData.documentId });
                  }
                }}></input>
          <div
            className=""
            data-cy={`${props.selectedIndex[index] === index ? "selected-card" : "not-selected"}`}
          >
            <div
            style={{
              fontWeight:600
            }}
              className="flex-center-children-horizontally font-size-large padding-left-larger padding-right-larger padding-top-default"
              data-cy="selectable-card-title"
            >
              {data.name}
            </div>
            {data.status === "FULL" ? (
              <div className="flex-center-children-horizontally font-size-small font-color-tertiary padding-default">SLOTS FULL</div>
            ) : data.endsAt <= currentDate ? (
              <div className="flex-center-children-horizontally font-size-small font-color-tertiary padding-default">COMPLETED</div>
            ) : (
              <div className="flex-center-children-horizontally padding-default font-size-small padding-default">
                <div className="padding-right-default">{dateAndTimeConverter(data.startsAt, "cardDate")}</div>
                <div className="padding-left-default border-left">{`${dateAndTimeConverter(data.startsAt, "Time")} - ${dateAndTimeConverter(
                  data.endsAt,
                  "Time"
                )}`}</div>
              </div>
            )}
          </div>
        </label>
      </div>
    )})
  );
};

const RegistrationFrom = (props) => {
  return (
    <div>
      <form
        className="flex-center-vertically-direction-column"
        data-cy="registration-from"
        onChange={props.formOnChange}
        onSubmit={props.formOnSubmit}
      >
        <InputBox
          className="inherit-parent-width max-width-300px border-radius-default margin-vertical-large"
          name="userName"
          label="Name"
          type="text"
          value={props.registerFormData.userName}
          autoComplete="false"
          required
          data-cy="name-field"
        />
        <InputBox
          className="inherit-parent-width font-size-small max-width-300px border-radius-default margin-vertical-large"
          name="phoneNumber"
          label="Phone Number"
          type="tel"
          value={props.registerFormData.phoneNumber}
          autoComplete="false"
          required
          data-cy="phoneNumber-field"
        />
        <InputBox
          className="inherit-parent-width font-size-small max-width-300px border-radius-default margin-vertical-large"
          name="confirmPhoneNumber"
          label="Confirm Phone Number"
          type="tel"
          value={props.registerFormData.confirmPhoneNumber}
          autoComplete="false"
          confirmNumber={props.registerFormData.phoneNumber}
          required
          validation={(value) => {
            if (props.registerFormData.phoneNumber !== value) {
              return {
                status:false,
                message: "Phone numbers mismatch"
              }
            } else {
              return {
                status:true
              }
            }
          }}
          data-cy="confirmPhoneNumber-field"
        />
        <div className="margin-vertical-large adaptive-padding-registrationForm user-select-none adaptive-text-align">
          Total Amount:
          <>
            <span
              className={`${
                props.amount.cost === 0 || props.amount.cost === "0.00" ? "opacity-0" : null
              } padding-top-default padding-right-default font-family-gilroy-medium font-color-primary`}
              data-cy="selected-card-amount"
            >
              <span> &#8377;</span>
              {props.amount.cost}
            </span>
            <del className={`${props.amount.mrp === 0 || props.amount.mrp === "0" ? "opacity-0" : null} font-size-small`}>
              <span>&#8377;</span>
              {props.amount.mrp}
            </del>
          </>
        </div>
        <Button
          data-cy="register-button"
          type="submit"
          boxShadow={false}
          className="max-width-150px margin-bottom-large "
          text="Register"
          buttonVariant="filled"
          loading={props.registerButtonLoading}
          disabled={
            props.registerFormData.phoneNumber !== "" &&
            (props.registerFormData.phoneNumber.length >= 10 || props.registerFormData.phoneNumber.length <= 14) &&
            props.registerFormData.userName.length > 2 &&
            props.registerFormData.phoneNumber === props.registerFormData.confirmPhoneNumber
              ? false
              : true
          }
        />
      </form>
    </div>
  );
};
