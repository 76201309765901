import React from "react";
import "./Header.css";
// import search from "../../assets/icons/Search.svg";
import apolloLogo from "../../assets/images/ApolloLogo.svg";
import phone1 from "../../assets/images/ph1.svg";
import { useHistory } from "react-router-dom";
import phone2 from "../../assets/images/ph2.svg";

function Header() {
  const history = useHistory();
  const scrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
  };

  return (
    <>
      <header className="background-white user-select-none border-bottom-2px flex-center-children-vertically header-position-fixed height-64px">
        <div className="inherit-parent-height inherit-parent-width padding-left-240px padding-right-240px">
          <div className="inherit-parent-height display-flex flex-justify-content-space-between">
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}} data-cy="display-flex flex-center-children-vertically">
              <img
                draggable="false"
                src={apolloLogo}
                data-cy="header-apollo-logo"
                alt="logo"
                style={{height:"80%", width:"auto"}}
                onClick={(event) => {
                  history.push("/");
                  scrollToTop(event);
                }}
                className="width-76px-height-40px web-margin-left-large cursor-pointer"
              />
              <div>
                <a href="tel:04445605000">
                  <img
                    draggable="false"
                    src={phone1}
                    data-cy="header-apollo-logo"
                    alt="logo"
                    style={{height:"30%", width:"auto", display:"block"}}
                    className="width-76px-height-40px web-margin-right-large cursor-pointer"
                  />
                </a>
                <a href="tel:+919150002771">
                  <img
                    draggable="false"
                    src={phone2}
                    data-cy="header-apollo-logo"
                    alt="logo"
                    style={{height:"30%", width:"auto", display:"block"}}
                    className="width-76px-height-40px web-margin-right-large cursor-pointer"
                  />
                </a>
              </div>
            </div>
            <div className="flex-center-children-vertically" data-cy="">
              {/* <div className="display-flex  margin-right-larger">
                <img draggable="false" src={search} data-cy="header-search-logo" alt="logo" className="height-width-14px cursor-pointer" />
                <div className=" mob-display-none font-size-medium cursor-pointer padding-left-small" data-cy="header-search-word">
                  SEARCH
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
