import { api } from "../utils/constants";

export async function getCampaignData() {
  const response = await fetch(`${api.baseUrl}getCampaigns`, {
    method: "POST"
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function createOrder(campaignId, selectedSpecialties, userName, phoneNumber) {
  const response = await fetch(`${api.baseUrl}createOrder`, {
    method: "POST",
    body: JSON.stringify({
      campaignId: campaignId,
      specialties: selectedSpecialties,
      name: userName,
      phoneNumber: phoneNumber
    }),
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json"
    }
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

// {
//  campaignId:"",
//  specialties:[""],
//  name:"",
//  phoneNumber:"",
// }
