import React, { useEffect, useState } from "react";
import "./App.css";
import PaymentStatus from "./components/PaymentStatus/PaymentStatus";
import SnackBar from "./components/SnackBar/SnackBar";
import Header from "./components/Header/Header";
import "./index.css";
import LandingScreen from "./Screens/LandingScreen/LandingScreen";
import { getCampaignData } from "./services/api";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import email from "./assets/icons/Email.svg";
import location from "./assets/images/Location.svg";
import phone from "./assets/icons/Phone.svg";
import Footer from "./components/Footer/Footer";

function App(props) {
  const [campaigns, setCampaigns] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  const getWindowSize = () => {
    setWindowInnerWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getWindowSize);
    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await getCampaignData();
    const data = response.data;
    let resultObject = {};

    for (const key in data) {
      const value = { ...data[key], documentId: key };
      resultObject[key] = {
        ...value
      };
    }
    setCampaigns(resultObject);
    setLoading(false);
  };

  useEffect(() => {
    if (props.message) {
      setMessage(props.message);
    }
  }, [props.message, message]);

  useEffect(() => {
    fetchData();
  }, []);

  const removeSnackBar = () => {
    setMessage(null);
    setType(null);
  };

  const removePayment = () => {
    setPaymentStatus(null);
  };

  return (
    <Router>
    <Switch>
      <Route exact path="/">
        {typeof paymentStatus !== "string" ? (
          <>
          <div className="">
            <LandingScreen
              campaigns={campaigns}
              loading={loading}
              message={setMessage}
              type={setType}
              payment={setPaymentStatus}
              windowInnerWidth={windowInnerWidth}
            />
          </div>
          <SnackBar
            message={message}
            type={type ? "success" : "error"}
            status={{
              type: type ? "success" : "error",
              message: message
            }}
            removeStatus={() => {
              removeSnackBar();
            }}
          />
        </>
        ) : (
          <PaymentStatus
          type={paymentStatus}
          onClick={() => {
            removePayment();
          }}
        />
        )}
      </Route>
      <Route exact path="/about">
        <div style={{height:"100%", position:"relative"}} >
          <div>
            <Header />
          </div>
          <div className="padding-left-240px padding-right-240px " style={{marginTop:"108px"}}>
            <div className="padding-large border-radius-default-web  background-white outline-plain inherit-parent-width">
            <h2  className="">ABOUT US</h2>
           <p className="margin-medium " style={{lineHeight:"1.4"}}>Apollo Clinic is a multi-specialty facility located in Madipakkam, Chennai, Tamil Nadu. This clinic is equipped with modern technology and the latest equipment to provide a diverse and efficient care to the patients from Nanganallur, Madippakkam, Keelkatalai, Adambakkam, Medavakkam, Kovilambakkam, Ullagaram, Vanuvampet and neighbouring areas.</p>
           </div>
        <div className="padding-large border-radius-default-web  background-white outline-plain inherit-parent-widt margin-top-large">
           <h2 className="">CONTACT US</h2>
           <div className="margin-medium">
          <div className="font-size-default">
            <div className="" style={{display:"flex", alignItems: "center"}}>
              <div className="">
                <a href="mailto:madipakkam@apolloclinic.com" style={{display: "flex", alignItems:"center"}} className="padding-vertical-default">
                  <img style={{height:"14px"}} className="padding-right-default" data-cy="footer-email-icon" draggable="false" src={email} alt="" /> 
                  <span>madipakkam@apolloclinic.com</span>
                </a>
                <span style={{display: "flex", alignItems:"center"}} className="padding-vertical-default">
                  <img style={{height:"14px"}} className="padding-right-default" data-cy="footer-email-icon" draggable="false" src={phone} alt="" /> 
                  <a href="tel:04445605000">04445605000</a>/&nbsp;
                  <a href="tel:+919150002771">+919150002771</a>
                </span>
                <div style={{display: "flex", alignItems:"start", lineHeight:"1.4"}} className="padding-vertical-default" >
                  <img className="padding-right-default" style={{height:"18px"}} draggable="false" src={location} alt="" />
                  <span style={{cursor: "pointer"}} className="padding-right-default">74, 1st floor, Medavakkam Main Rd, <br/> above Singapore Shop, Bharat Nagar, <br/> Madipakkam, Chennai, TN-600091</span>
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>
          </div>
           
        <div style={{position:'absolute', bottom:"0px", width:"100%"}}>
          <Footer/>
        </div>
        </div>
      </Route>
      <Route exact path="/contact">
      <div style={{height:"100%", position:"relative"}} >
          <div>
            <Header />
          </div>
          <div className="padding-left-240px padding-right-240px " style={{marginTop:"108px"}}>
            <div className="padding-large border-radius-default-web  background-white outline-plain inherit-parent-width">
            <h2  className="">ABOUT US</h2>
           <p className="margin-top-medium " style={{lineHeight:"1.4"}}>Apollo Clinic is a multi-specialty facility located in Madipakkam, Chennai, Tamil Nadu. This clinic is equipped with modern technology and the latest equipment to provide a diverse and efficient care to the patients from Nanganallur, Madippakkam, Keelkatalai, Adambakkam, Medavakkam, Kovilambakkam, Ullagaram, Vanuvampet and neighbouring areas.</p>
           </div>
        <div className="padding-large border-radius-default-web  background-white outline-plain inherit-parent-widt margin-top-large">
           <h2 className="">CONTACT US</h2>
           <div className="margin-top-medium">
          <div className="font-size-default">
            <div className="" style={{display:"flex", alignItems: "center"}}>
              <div className="">
                <a href="mailto:madipakkam@apolloclinic.com" style={{display: "flex", alignItems:"center"}} className="padding-vertical-default">
                  <img style={{height:"14px"}} className="padding-right-default" data-cy="footer-email-icon" draggable="false" src={email} alt="" /> 
                  <span>madipakkam@apolloclinic.com</span>
                </a>
                <span style={{display: "flex", alignItems:"center"}} className="padding-vertical-default">
                  <img style={{height:"14px"}} className="padding-right-default" data-cy="footer-email-icon" draggable="false" src={phone} alt="" /> 
                  
                  <a href="tel:04445605000">04445605000</a>/&nbsp;
                  <a href="tel:+919150002771">+919150002771</a>
                </span>
                <div style={{display: "flex", alignItems:"start", lineHeight:"1.4"}} className="padding-vertical-default" >
                  <img className="padding-right-default" style={{height:"18px"}} draggable="false" src={location} alt="" />
                  <span style={{cursor: "pointer"}} className="padding-right-default">74, 1st floor, Medavakkam Main Rd, <br/> above Singapore Shop, Bharat Nagar, <br/> Madipakkam, Chennai, TN-600091</span>
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>
          </div>
           
        <div style={{position:'absolute', bottom:"0px", width:"100%"}}>
          <Footer/>
        </div>
        </div>
      </Route>
      <Route exact path="/refund">
        
      </Route>
      <Route exact path="/privacy">
      <div className="padding-left-240px padding-right-240px">
        <div>
          <Header />
        </div>
        <div style={{marginTop:"108px"}} className=" margin-bottom-larger padding-large border-radius-default-web  background-white outline-plain ">
          <h2 >PRIVACY POLICY</h2>
          <p className="margin-top-medium" style={{lineHeight:"1.4"}}>
Swarnam  Health  and  Lifestyle  LLP  recognises  the importance of maintaining your privacy. We 
value your privacy and appreciate your trust in us. This Privacy Policy sets out how Swarnam Health 
and Lifestyle LLP uses and protects any information that you give Swarnam Health and Lifestyle LLP 
when  you  use  this  www.apollomadipakkam.com  or  the Swarnam Health and Lifestyle LLP mobile 
application or any other digital medium and other ohline sources of our Company. Swarnam Health and 
Lifestyle LLP is committed to ensure that your privacy is protected. Should we ask you to provide 
certain information by which you can be identified when using this website, then you can be assured 
that it will only be used in accordance with this Privacy Policy as it describes how we treat user 
information we collect from you, the policies and procedures on the  collection,  use,  disclosure  
and  protection  of  your information when you use our Swarnam Health and Lifestyle LLP Platform.
This Privacy Policy applies to current and former visitors to our Swarnam Health and Lifestyle LLP 
Platform and to our online customers. By visiting and/or using our website, you agree to this 
Privacy Policy. Swarnam Health and Lifestyle LLP may change this policy from time to time by 
updating this page. You should check this page from time to time to ensure that you are happy with 
any changes.
www.apolIomadipakkam.com is a property of Swarnam Health and Lifestyle LLP, LLP, established under 
laws of India, having its registered oPice at LLP, Tamil Nadu - 600091.

<h3 className="margin-vertical-medium">DEFINITIONS:</h3>

The terms "SHLL", "Company", "we", "us" and "our" refer to the Swarnam Health and Lifestyle LLP.
The term "Swarnam Health and Lifestyle LLP Platform" refers to www.apollomadipakkam.com or the 
Swarnam Health and Lifestyle LLP mobile application or any other digital medium and other offline 
sources.
The  terms  "you"  and  "your"  refer  to  the  user  of  the www.apoIlomadipakkam.com.
The term "Group" means, with respect to any person, any entity that is controlled by such person, 
or any entity that controls such person, or any entity that is under common control with such 
person, whether directly or indirectly, or, in the case of a natural person, any Relative (as such 
term is defined in the Companies Act, 1956 and Companies Act, 2013 to the extent applicable) of 
such person.
The term "Services"  refers to any services oPered by Swarnam Health and Lifestyle LLP whether on 
the Swiggy Platform or otherwise.
The term "Policy" refers to this Privacy Policy.
Please read this Policy before using the Swarnam Health and  Lifestyle  LLP  Platform  or  
submitting  any  personal information to Swarnam Health and Lifestyle LLP. This Policy is a part of 
and incorporated within, and is to be read along with, the Terms of Use 
https://apolIomadipakkam.com/terms.

<h3 className="margin-vertical-medium">INFORMATION WE COLLECT FROM YOU:</h3>
Contact information:
We might collect your Name, Email, Phone, Address, IP
Address.

Payment and billing information:
We might collect your billing name, billing address and payment method when you buy a 
producVservice. We NEVER collect your credit card number or credit card expiry date or other 
details pertaining to your credit card on our website. Credit card information will be obtained and 
processed by our online payment partner RazorPay.
Information you post:
We collect information you post in a public space on our Swarnam Health and Lifestyle LLP Platform 
or on a third-party social media site belonging to Swarnam Health and Lifestyle LLP.
Usage and Preference Information:
We collect information as to how you interact with our Services, preferences expressed and settings 
chosen. Swarnam Health and Lifestyle LLP Platform includes the Swarnam Health and Lifestyle LLP 
advertising services ("Ad Services"), which may collect user activity and browsing history within 
the Swarnam Health and Lifestyle LLP Platform and across third-party sites and online services, 
including those sites and services that include our ad pixels ("Pixels"), widgets, plug-ins, 
buttons, or related services or through the use of cookies. Our Ad Services collect browsing 
information including, without limitation, your Internet  protocol  (IP)  address  and  location,  
your  login information, browser type and version, date and time stamp, user agent, Swarnam Health 
and Lifestyle LLP cookie ID (if applicable),  time zone setting,  browser  plug-in  types and 
versions, operating system and platform, and other information about user activities on the Swarnam 
Health and Lifestyle LLP Platform, as well as on third party sites and services that have embedded 
our Pixels, widgets, plug-ins, buttons, or related services.

Transaction Information:
We collect transaction details related to your use of our Services, and information about your 
activity on the Services, including the full Uniform Resource Locators (URL), the type of Services 
you requested or provided, comments, domain names, search results selected, number of clicks, 
information and pages viewed and searched for, the order of those pages, length of your visit to 
our Services, the date and time you used the Services, amount charged, details regarding 
application of promotional code, methods used to browse away from the page and any phone number 
used to call our customer service number and other related transaction details.
Stored information and files:
Swarnam  Health  and  Lifestyle  LLP  mobile  application (Swarnam Health and Lifestyle LLP app) 
may also access metadata and other information associated with other files stored on  your  mobile  
device.  This  may  include,  for  example, photographs, audio and video clips, personal contacts 
and address book information. If you permit the Swarnam Health and Lifestyle LLP app to access the 
address book on your device, we may collect names and contact information from your address book to 
facilitate social interactions through our services and for other purposes described in this Policy 
or at the time of consent or collection. If you permit the Swarnam Health and Lifestyle LLP app to 
access the calendar on your device, we collect calendar information such as event title and 
description, your response (Yes, No, Maybe), date and time, location and number of attendees. If 
you are a partner company, we will, additionally, record your calls with us made from the device 
used to provide Services, related call details, SMS details location and address details.

Demographic information:
We may collect demographic information about you, events you like, events you intend to participate 
in, tickets you buy, or any other information provided by you during the use of Swarnam Health and 
Lifestyle LLP Platform. We might collect this as a part of a survey also.
Other information:
If you use Swarnam Health and Lifestyle LLP Platform, we may collect information about your IP 
address and the browser you're using. We might look at what site you came from, duration of time 
spent on our website, pages accessed or what site you visit when you leave us. We might also 
collect the type of mobile device you are using, or the version of the operating system your 
computer or device is running.

<h3 className="margin-vertical-medium">WAYS OF COLLECTING INFORMATION:</h3>

We collect information directly from you:
We collect information directly from you when you register on Swarnam Health and Lifestyle LLP 
Platform. We also collect information if you post a comment on Swarnam Health and Lifestyle LLP 
Platform or ask us a question through phone or email or any other source of communication.
We collect information from you passively:
We  use  tracking  tools  like  Google  Analytics,  Google Webmaster, Google Cookies, Web Beacons 
for collecting information about your usage of our website.
We get information about you from third parties:
We may receive information about you from third parties, such as other users, partners (including 
ad partners, analytics providers, search information providers), or our aPiliated companies or if 
you use any of the other websites/apps we operate or the other Services we provide. Users of our Ad 
Services and other third- parties may share information with us such as the cookie ID, device ID, 
or demographic or interest data, and information about

content viewed or actions taken on a third-party website, online services or apps. For example, 
users of our Ad Services may also be able to share customer list information (e.g., email or phone 
number) with us to create customized audience segments for their ad campaigns.
When you sign in to Swarnam Health and Lifestyle LLP Platform with your SNS account, or otherwise 
connect to your SNS account with the Services, you consent to our collection, storage, and use, in 
accordance with this Policy, of the information that you make available to us through the social 
media interface. This could include, without limitation, any information that you have made public 
through your social media account, information that the social media service shares with us, or 
information that is disclosed during the sign-in process. Please see your social media provider's 
privacy policy and help center for more information about how they share information when you 
choose to connect your account.
If you are partner company, we may, additionally, receive feedback and rating from other users.

<h3 className="margin-vertical-medium">USE OF YOUR PERSONAL INFORMATION:</h3>

We use information to contact you:
We might use the information you provide to contact you for confirmation of a purchase on Swarnam 
Health and Lifestyle LLP Platform or for other promotional purposes.
We use information to abide by the contracts:
We might use the information you provide to carry out our obligations arising from any contracts 
entered into between you and us and to provide you with the relevant information and services.
We  use  information  to  respond  to  your  requests  or
questions:
We might use your information to confirm your registration for rendering services we oher.
We use information to improve our products and services:

We might use your information to customize your experience with us. This could include displaying 
content based upon your preferences. We might also use your information to understand our users 
(what they do on our Services, what features they like, how they use them, etc.), improve the 
content and features of our Services (such as by personalizing content to your interests), process 
and complete your transactions, make special offers, provide customer support, process and respond 
to your queries and to generate and review reports and data about, and to conduct research on, our 
user base and Service usage patterns. If you are a paJner company, we may use the information to 
track the progress of your service to our customers.
We use information to look at site trends and customer
interests:
We may use your information to make our website and products better. We may combine information we 
get from you with information about you we get from third pa%ies. We may use your information to 
provide you with information about services we consider similar to those that you are already 
using, or have enquired about, or may interest you. If you are a registered user, we will contact 
you by electronic means (e-mail or SMS or telephone) with information about these services.
We use information for security purposes:
We may use information to protect our company, our customers, or our Swarnam Health and Lifestyle 
LLP Platform.
We use information for marketing purposes:
We might send you information about special promotions or oPers. We might also tell you about new 
features or products or enroll you in our newsletter. These might be our own oPers or products, or 
third-party oPers or products we think you might find interesting.  We may use the information to 
measure or understand the effectiveness of advertising we serve to you and others, and to deliver 
relevant advertising to you.
We   use   information   to   send   you   transactional communications:

We might send you emails or SMS about your account or purchase of our product.
We use information as otherwise permitted by law:
We may combine the information that we receive from third parties with the information you give to 
us and information we collect about you for the purposes set out above. Further, we may anonymize 
and/or de-identify information collected from you through the Services or via other means, 
including via the use of third-party web analytic tools. As a result, our use and disclosure of 
aggregated and/or de-identified information is not restricted by this Policy, and it may be used 
and disclosed to others without limitation.
We analyse the log files of our Swarnam Health and Lifestyle LLP Platform that may contain Internet 
Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring, app 
crashes, page viewed and exit websites and applications, operating system, date/time stamp, and 
clickstream data. This helps us to administer the website, to learn about user behavior on the 
site, to improve our product and services, and to gather demographic information about our user 
base as a whole.

<h3 className="margin-vertical-medium">SHARING/DISCLOSURE/DISTRIBUTION OF YOUR INFORMATION WITH THIRD-PARTIES:</h3>

We will share information with third parties who perform services on our behalf:
We may share your information with our vendors, consultants,
marketing partners, research firms and other service providers or business partners, such as 
payment processing companies, to support our business. For example, your information may be shared 
with outside vendors to send you emails and messages or push notifications to your devices in 
relation to our Services, to help us analyze and improve the use of our Services, to process and 
collect payments.
We may also use vendors for other projects, such as conducting surveys or organizing sweepstakes 
for us. Some vendors may be

located outside of India.
We will share information with Advertisers and advertising
networks:
We may work with third parties such as network advertisers to serve advertisements on the Swarnam 
Health and Lifestyle LLP Platform and on third-party websites or other media (e.g., social 
networking platforms). These third parties may use cookies, JavaScript, web beacons (including 
clear GIFs), Flash LSOs  and  other  tracking  technologies  to  measure  the ehectiveness of their 
ads and to personalize advertising content to you.
While you cannot opt out of advertising on the Swarnam Health and Lifestyle LLP Platform, you may 
opt out of much interest- based advertising on third party sites and through third party ad 
networks  (including  DoubleClick  Ad  Exchange,  Facebook Audience Network and Google AdSense). 
Opting out means that you will no longer receive personalized ads by third parties ad networks from 
which you have opted out, which is based on your browsing information across multiple sites and 
online services. If you delete cookies or change devices, your ‘opt out’ may no longer be elective 
to fulfill the purpose for which you provide it.
We will share information with our business partners:
This includes a third party who provide us various services. Our partners use the information we 
give them as described in their privacy policies.
While you place a request to order through the Swarnam Health and Lifestyle LLP Platform, your 
information is provided to us and to the vendors with whom you may choose to order. In order to 
facilitate your online order processing, we provide your information to that vendors in a similar 
manner as if you had made a goods/products/food order directly with the vendors. If you provide a 
mobile phone number, Swarnam Health and Lifestyle LLP may send you text messages regarding the 
orders delivery status.
We will share information with Other Users:

If you are a delivery partner, we may share your name, phone number and/or profile picture (if 
applicable), tracking details with other users to provide them the Services.
We may share information if we think we have to in order to comply with the law or to protect 
ourselves:
We may also share it if a government agency or investigatory body or other companies assisting us, 
requests when we are:
Obligated under the applicable laws or in good faith to respond to court orders and processes; or
Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of 
Services and other illegal acts; Responding to claims that an advertisement, posting or other 
content violates the intellectual property rights of a third party; Under a duty to disclose or 
share your personal data in order to enforce our Terms of Use and other agreements, policies or to 
protect the rights, property, or safety of the Company, our customers, or others, or in the event 
of a claim or dispute relating to your use of our Services. This includes exchanging information 
with other companies and organisations for the purposes of fraud detection and credit risk 
reduction.
We may share information for Internal Use or may share it
with any successor to all or part of our business:
For example, if part of our business is sold we may give our customer list as part of that 
transaction. We may also share your information with any present or future member of our "Group" 
(as defined above) or aPiliates for our internal business purposes
We may share your information for reasons not described in
this policy:
We may share your information other than as described in this Policy if we notify you and you 
consent to the sharing. We will tell you before we do this.

<h3 className="margin-vertical-medium">YOUR CONSENT:</h3>

By using the Swarnam Health and Lifestyle LLP Platform and the Services provided therein, you agree 
and consent to the collection, transfer, use, storage, disclosure and sharing of your information 
as described and collected by us in accordance with this Policy. If you do not agree with the 
Policy, please do not use or access the Swarnam Health and Lifestyle LLP Platform.

<h3 className="margin-vertical-medium">COOKIES:</h3>

Our Swarnam Health and Lifestyle LLP Platform and third parties with whom we partner, may use 
cookies, pixel tags, web beacons, mobile device IDs, "flash cookies" and similar files or 
technologies to collect and store information with respect to your use of the Services and 
third-party websites.
Cookies are small files that are stored on your browser or device by websites, apps, online media 
and adveJisements. We use cookies and similar technologies for purposes such as:
Authenticating users;
Remembering user preferences and settings; Determining the popularity of content;
Delivering and measuring the effectiveness of advertising campaigns;
Analysing site traffic and trends, and generally understanding the online behaviours and interests 
of people who interact with our services.
A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier, 
embedded invisibly on a webpage (or an online ad or email), and is used to count or track things 
like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on 
users' computers. We use pixel tags to measure the popularity of our various pages, features and 
services. We also may include web beacons in e- mail messages or newsletters to determine whether 
the message has been opened and for other analytics.

To modify your cookie settings, please visit your browser's settings. By using our Services with 
your browser settings to accept cookies, you are consenting to our use of cookies in the manner 
described in this section.
We  may  also allow  third  parties  to provide  audience measurement  and  analytics  services  
for  us,  to  serve advertisements on our behalf across the Internet, and to track and report on 
the performance of those advertisements. These entities may use cookies, web beacons, SDKs and 
other technologies to identify your device when you visit the Swarnam Health and Lifestyle LLP 
Platform and use our Services, as well as when you visit other online sites and services.
Please see our Cookie Policy here for more information regarding the use of cookies and other 
technologies described in this section, including regarding your choices relating to such 
technologies.

<h3 className="margin-vertical-medium">DATA SECURITY PRECAUTIONS:</h3>

We have  in  place  appropriate  technical  and  security measures to secure the information 
collected by us. We use vault and tokenization services from third party service providers to 
protect the sensitive personal information provided by you. The third-party service providers with 
respect to our vault and tokenization services and our payment gateway and payment processing  are 
compliant  with the payment card industry standard  (generally  referred  to as PCI compliant  
service providers). You are advised not to send your full credit/debit card details through 
unencrypted electronic platforms. Where we have given you (or where you have chosen) a username and 
password which enables you to access certain parts of the Swarnam  Health  and  Lifestyle  LLP  
Platform,  you  are responsible for keeping these details confidential. We ask you not to share 
your password with anyone.

Please be aware that the transmission of information via the internet is not completely secure. 
Although we will do our best to protect your personal data, we cannot guarantee the security of 
your data transmitted through the Swarnam Health and Lifestyle  LLP  Platform.  Once  we  have  
received  your information, we will use strict physical, electronic, and procedural safeguards to 
try to prevent unauthorised access.

<h3 className="margin-vertical-medium">Email OPT-OUT:</h3>

You can opt out of receiving our marketing emails. To stop receiving   our   promotional   emails,  
 please   email (optout@swarnamhll.com). It may take about     days to process your request. Even 
if you opt out of getting marketing messages, we will still be sending you transactional messages 
through email and SMS about your purchases.

<h3 className="margin-vertical-medium">THIRD PARTY SITES:</h3>

The Swarnam Health and Lifestyle LLP Platform may contain links to other websites. If you click on 
one of the links to third party websites, you may be taken to websites we do not control. Any 
personal information about you collected whilst visiting such websites is not governed by this 
Policy. Swarnam Health and Lifestyle LLP shall not be responsible for and has no control over the 
practices and content of any website accessed using the links contained on the Swarnam Health and 
Lifestyle LLP Platform.  Read the privacy  policy of other websites carefully as this policy does 
not apply to the privacy practices of those websites. We are not responsible for these third party 
sites.
This Policy shall also not apply to any information you may disclose to any of our service 
providers/service personnel which we do not require you to disclose to us or any of our service 
providers under this Policy.

<h3 className="margin-vertical-medium">GRIEVANCE OFFICER:</h3>

In accordance with Information Technology Act 2000 and rules made and applicable there under, the 
name and contact details of the Grievance Officer are provided below:
Name:
Address:
Phone Number:
Email Address:

<h3 className="margin-vertical-medium">JURISDICTION:</h3>

If you choose to visit the website, your visit and any dispute over privacy is subject to this 
Policy and the website's terms of use. In addition to the foregoing, any disputes arising under 
this Policy shall be governed by the laws of India.
Further, please note that the Swarnam Health and Lifestyle LLP Platform  stores  your  data  with 
the cloud  platform  of Firebase provided by (Name of the Service Provider), which may store this 
data on their servers located India. Firebase has security measures in place to protect the loss, 
misuse and alteration of the information, details of which are available at 
https://firebase.google.com/. The privacy policy adopted by Firebase         are         detailed   
      in https://firebase.google.com/support/privacy.
In the event you have questions or concerns about the security measures adopted by Firebase, you 
can contact their data  protection/  privacy  team/  legal  team  or  designated grievance oPicer 
at these organisations, whose contact details are available in its privacy policy, or can also 
write to our Grievance Officer at the address provided above.

If you have any queries relating to the processing or usage of information provided by you in 
connection with this Policy or other   privacy   concerns,   you   can   email   us   at 
cs@swarnamhll.com.</p>

        </div>
        <div></div>
          <Footer/>
        </div>
      </Route>
      <Route exact path="/terms">
        <div>
          <Header/>
          <div className="padding-left-240px padding-right-240px margin-bottom-larger" style={{marginTop:"108px"}}>
            <div className="padding-large border-radius-default-web  background-white outline-plain">
            <h2 >TERMS OF SERVICE AGREEMENT</h2>
            <p className="margin-top-medium" style={{lineHeight:"1.4"}}>
Welcome to www.apollomadipakkam.com. This website is owned and operated by Swarnam Health and 
Lifestyle LLP & 74,  1st  floor,  Medavakkam  Main  Rd,Bharat  Nagar, Madipakkam,Chennai,Tamil  
Nadu,600091.  By  visiting  our website and accessing the information, resources, services, 
products, and tools we provide, you understand and agree to accept and adhere to the following 
terms and conditions as stated in this policy (hereinafter referred to as the ‘Agreement’), along 
with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy 
section below for more information).
We reserve the right to change this Agreement from time to time with/without notice. You 
acknowledge and agree that it is your responsibility to review this Agreement periodically to 
familiarize yourself with any modifications. Your continued use of this site after such 
modifications will constitute acknowledgment and agreement of the modified terms and conditions.
PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS 
FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.

This  Agreement  governs  your  use  of  this  website www.apoIlomadipakkam.com (hereinafter 
referred to as the ‘Website’), Swarnam Health and Lifestyle LLPc's oher of Products and Services 
for purchase on this Website, or your purchase of Products and Services available on this Website. 
This Agreement includes and incorporates by this reference, the policies and guidelines referred 
below. Swarnam Health and Lifestyle LLP reserves the right to change or revise the terms and 
conditions of this Agreement at any time by posting any changes or a revised Agreement on this 
Website. Swarnam Health and Lifestyle LLP will/will not alert you that changes or revisions have 
been made by indicating on the top of this Agreement the date it was last revised. The changed or 
revised Agreement will be effective immediately after it is posted on this Website. Your use of the 
Website following the posting of any such changes or of a revised Agreement will constitute your 
acceptance of any such changes or revisions. Swarnam Health and Lifestyle LLP encourages you to 
review this Agreement whenever  you  visit  the  Website  to  make  sure  that  you understand the 
terms and conditions governing use of the Website. This Agreement does not alter in any way the 
terms or conditions of any other written agreement you may have with Swarnam Health and Lifestyle 
LLP for other products or services. If you do not agree to this Agreement (including any referenced 
policies or guidelines), please immediately terminate your use of the Website.
Responsible Use And Conduct

In order to access our Resources/ Products and Services, you may be required to provide certain 
information about yourself (such as identification, email, phone number, contact details, etc.) as 
part of the registration process, or as part of your ability to use the Resources. You agree that 
any information you provide will always be accurate, correct, and up to date.

You are responsible for maintaining the confidentiality of any login information associated with 
any account you use to access our Resources/ Products and Services. Accordingly, you are 
responsible for all activities that occur under your accounts.
Accessing (or attempting to access) any of our Resources/ Products and Services by any means other 
than through the means we provide, is strictly prohibited. You specifically agree not to access (or 
attempt to access) any of our Resources/ Products and Services through any automated, unethical or 
unconventional means.
Engaging in any activity that disrupts or interferes with our Resources/  Products  and Services,  
including  the servers and/or networks to which our Resources  / Products and Services are located 
or connected, is strictly prohibited.
Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources / Products and 
Services is strictly prohibited.
You are solely responsible for any consequences, losses, or damages that we may directly or 
indirectly incur or suPer due to any unauthorized activities conducted by you, as explained above, 
and may incur criminal or civil liability.
Privacy:

Swarnam Health and Lifestyle LLP believes  strongly in protecting user privacy, which is why a 
separate Privacy Policy have been created in order to explain in detail how we collect, manage, 
process, secure, and store your private information. Please refer to Swarnam Health and Lifestyle 
LLP privacy policy, incorporated by reference herein, that is posted on the Website.
Products and Services - Terms of Offer:

This Website ohers for sale certain Products and Services (hereinafter referred to as Products and 
Services'). By_placing an order for Products and Services through this Website,_you agree to the 
terms set forth in this Agreement.

<h3 className="margin-vertical-medium">Customer Solicitation:</h3>

Unless you notify our third party call center representatives or direct  Swarnam  Health  and  
Lifestyle  LLP  sales representatives, while they are calling you, of your desire to opt out  from  
further  direct  company  communications  and solicitations, you are agreeing to continue to 
receive further emails and call solicitations from Swarnam Health and Lifestyle LLP and its 
designated in house or third party call team(s).
Opt Out Procedure:

We provide 3 easy ways to opt out of from future solicitations - You may use the opt out link found 
in any email solicitation that you may receive.
You may also choose to opt out, via sending your email address to: [opt-out email].
You may send a written remove request to Swarnam Health and Lifestyle LLP sales representatives, 
while they are calling you, of your desire to opt out from further direct company communications 
and solicitations, you are agreeing to continue to receive further emails and call solicitations 
from Swarnam Health and Lifestyle LLP & ADDRESS 74, 1st  floor,  Medavakkam  Main  Rd,Bharat  
Nagar, Madipakkam,Chennai,Tamil Nadu,600091.

<h3 className="margin-vertical-medium">Proprietary Rights:</h3>

Swarnam Health and Lifestyle LLP has proprietary rights and trade secrets in the Products and 
Services. You may not co|¿y, reproduce,  resell or redistribute any Product manufactured and/or 
distributed by Swarnam Health and Lifestyle LLP. Swarnam Health and Lifestyle LLP also has rights 
to all trademarks and trade dress and specific layouts of this webpage, including calls to action, 
text placement, images and other information.

Taxes: If you purchase any Products and Sewices,_you will be responsible for paying  the 
a|¿pIicabIe taxes. (GST)

<h3 className="margin-vertical-medium">Content, Intellectual Property, Third Party Links:</h3>


In addition to making  Products and Services available,  this Website also oPers information and 
marketing  materials. This Website also ohers information, both directly and through indirect links 
 to  third-party  websites,  about  (kind  of  information). Swarnam Health and Lifestyle LLP does 
not always create the information oPered on this Website; instead the information is often gathered 
from other sources. To the extent that Swarnam Health and Lifestyle LLP does create the content on 
this Website, such content is protected by intellectual property laws of  the  India,  foreign  
nations,  and  international  bodies. Unauthorized  use  of  the  material  may  violate  
copyright, trademark, and/or other laws. You acknowledge that your use of the content on this 
Website is for personal, non-commercial use. Any links to third-party websites are provided solely 
as a convenience to you. Swarnam Health and Lifestyle LLP does not endorse the contents on any such 
third-party websites. Swarnam Health and Lifestyle LLP is not responsible for the content of or any 
damage that may result from your access to or reliance on these third-party websites. If you link 
to third-party websites, you do so at your own risk.
Use of Website:

Swarnam Health and Lifestyle LLP is not responsible for any damages resulting from use of this 
website by anyone. You will not use the Website for illegal purposes. You will -
abide by all applicable local, state, national, and international laws and regulations in your use 
of the Website (including laws regarding intellectual property),
not interfere with or disrupt the use and enjoyment of the Website
by other users,
not resell material on the Website,

not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any 
other type of unsolicited communication, and
not defame, harass, abuse, or disrupt other users of the Website. License:

By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the 
content and materials on the Website in connection with your normal, non-commercial use of the 
Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such 
content or information without express written authorization  from Swarnam Health and Lifestyle LLP 
or the applicable third party (if third party content is at issue).
Blogs:
We may provide various open communication tools on our website, such as blog comments, blog posts, 
public chat, forums, message boards, newsgroups, product ratings and reviews, various social media 
services, etc. You understand that generally we do not pre-screen or monitor the content posted by 
users of these various communication tools, which means that if you choose to use these tools to 
submit any type of content to our website, then it is your personal responsibility to use these 
tools in a responsible and ethical manner. By posting information or otherwise using any open 
communication tools as mentioned, you agree that you will not upload, post, share, or otherwise 
distribute any content that:
is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, 
deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit 
language; infringes on any trademark, patent, trade secret, copyright, or other proprietary right 
of any party;
contains any type of unauthorized or unsolicited advertising;

impersonates   any  person  or  entity,   including   any www.apoIlomadipakkam.com/Swarnam Health 
and Lifestyle LLP employees or representatives.
We have the right at our sole discretion to remove any content that, we feel in our judgment does 
not comply with this User Agreement, along with any content that we feel is otherwise oPensive, 
harmful, objectionable, inaccurate, or violates any 3rd party copyrights or trademarks. We are not 
responsible for any delay or failure in removing such content. If you post content that we choose 
to remove, you hereby consent to such removal, and consent to waive any claim against us.
We do not assume any liability for any content posted by you or any other 3rd party users of our 
website. However, any content posted by you using any open communication tools on our website, 
provided that it doesn't violate or infringe on any 3rd party copyrights  or trademarks,  becomes 
the property of Swarnam Health and Lifestyle LLP, and as such, gives us a perpetual, irrevocable, 
worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, 
publicly display and/or distribute as we see fit. This only refers and applies to content posted 
via open communication tools as described, and does not refer to information that is provided as 
part of the registration process, necessary in order to use our Resources. All information provided 
as part of our registration process is covered by our Privacy Policy.
You   agree   to   indemnify   and   hold   harmless www.apoIlomadipakkam.com a digital property of 
Swarnam Health and Lifestyle LLP and its parent company and affiliates, and their directors, 
officers, managers, employees, donors, agents, and licensors, from and against all losses, 
expenses, damages  and  costs,  including  reasonable  attorneys'  fees, resulting from any 
violation of this User Agreement or the failure to fulfill any obligations relating to your account 
incurred by you or any other person using your account. We reserve the right to take over the 
exclusive defense of any claim for which we are

entitled to indemnification under this User Agreement. In such event, you shall provide us with 
such cooperation as is reasonably requested by us.
Posting:

By posting, storing, or transmitting any content on the Website, you hereby grant Swarnam Health 
and Lifestyle LLP a perpetual, worldwide, non-exclusive, royalty-free, assignable, right and 
license to use, copy, display, perform, create derivative works from, distribute, have distributed, 
transmit and assign such content in any form, in all media now known or hereinafter created, 
anywhere in the world. Swarnam Health and Lifestyle LLP does not have the ability to control the 
nature of the user- generated content offered through the Website. You are solely responsible for 
your interactions with other users of the Website and any content you post. Swarnam Health and 
Lifestyle LLP is not liable for any damage or harm resulting from any posts by or interactions 
between users. Swarnam Health and Lifestyle LLP reserves the right, but has no obligation, to 
monitor interactions between and among users of the Website and to remove any content Swarnam 
Health and Lifestyle LLP deems objectionable, in Swarnam Health and Lifestyle LLP's sole 
discretion.

<h3 className="margin-vertical-medium">Disclaimer of Warranties:</h3>

Your use of this website and/or Products and Services are at your sole risk. The website and 
Products and Services are oPered on an "as is" and "as available" basis. Swarnam Health and 
Lifestyle LLP expressly disclaims all warranties of any kind, whether express or implied, 
including, but not limited to, implied warranties of merchantability, fitness for a particular 
purpose and non-infringement with respect to the Products and Services or website content, or any 
reliance upon or use of the website content or Products and Services (" Products and Services" 
include trial Products and Services.)
Without limiting the generality of the foregoing, Swarnam Health and Lifestyle LLP makes no 
warranty:
that the information provided on this website is accurate, reliable, complete, or timely.
that the links to third-party websites are to information that is accurate, reliable, complete, or 
timely.
no advice or information, whether oral or written, obtained by you from this website will create 
any warranty not expressly stated herein
as to the results that may be obtained from the use of the products or that defects in products 
will be corrected.
rgarding any Products and Services purchased or obtained through the website.
Some jurisdictions do not allow the exclusion of certain warranties, so some of the above 
exclusions may not apply to yOU.

<h3 className="margin-vertical-medium">Limitation Of Liability</h3>

Swarnam Health and Lifestyle LLP's entire liability, and your exclusive remedy, in law, in equity, 
or otherwise, with respect to the website content / Products and Services and/or for any breach of 
this agreement is solely limited to the amount you paid, less shi|¿ping  and handling  charges,  
for Products and Services purchased via this website.
Swarnam Health and Lifestyle LLP will not be liable for any direct, indirect, incidental, special 
or consequential damages in connection with this agreement &/or Products and Services in any 
manner, including liabilities resulting from:
the use or the inability to use the website content / Products and
Services;
the cost  of  procuring  substitute  content  / Products  and
Services;
any information obtained / Products and Services purchased or transactions entered into through the 
website; or
any lost profits you allege.
Some jurisdictions do not allow the limitation or exclusion of liability for incidental or 
consequential damages so some of the above limitations may not apply to you.
conjunction with the Limitation of Liability as explained above, you expressly understand and agree 
that any claim against us shall be limited to the amount you paid, if any, for use of Products      
     and           Services. www.apoIlomadipakkam.com/Swarnam Health and Lifestyle LLP will not  be 
liable  for any direct,  indirect,  incidental, consequential or exemplary loss or damages which 
may be incurred by you as a result of using our Resources / Products and Services, or as a result 
of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full 
extent that applicable limitation of liability laws apply.

<h3 className="margin-vertical-medium">Indemnification:</h3>

You will release, indemnify, defend and hold harmless Swarnam Health and Lifestyle LLP, and any of 
its contractors, agents, employees,  ohicers,  directors,  shareholders,  aPiliates  and assigns 
from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and 
expenses, of third parties relating to or arising out of
this Agreement or the breach of your warranties, representations and obligations under this 
Agreement;
the Website content or your use of the Website content;
the Products and Services or your use of the Products and Services (including Trial Products and 
Services);
any intellectual property or other proprietary right of any person
or entity;
your violation of any provision of this Agreement; or
any information or data you supplied to Swarnam Health and
Lifestyle LLP.

When Swarnam Health and Lifestyle LLP is threatened with suit or sued by a third party, Swarnam 
Health and Lifestyle LLP may seek written assurances from you concerning your promise to indemnify 
Swarnam Health and Lifestyle LLP; your failure to provide such assurances  may be considered by 
Swarnam Health and Lifestyle LLP to be a material breach of this Agreement. Swarnam Health and 
Lifestyle LLP will have the right to participate in any defense by you of a third-party claim 
related to your use of any of the Website content / Products and Services, with the counsel of 
Swarnam Health and Lifestyle LLP's choice at its expense. Swarnam Health and Lifestyle LLP will 
reasonably cooperate in any defense by you of a third- party claim at your request and expense. You 
will have sole responsibility to defend Swarnam Health and Lifestyle LLP against any claim, but you 
must receive Swarnam Health and Lifestyle LLP's prior written consent regarding any related 
settlement.  The  terms  of  this  provision  will  survive  any termination or cancellation of 
this Agreement or your use of the Website / Products and Services.
Copyrights / Trademarks

All    content    and    materials    available    on www.apoIlomadipakkam.com, including but not 
limited to text, graphics,  website name, code, images and logos are the intellectual property of 
Swarnam Health and Lifestyle LLP and are protected by applicable copyright and trademark law. Any 
inappropriate use, including but not limited to the reproduction, distribution, display or 
transmission of any content on this site is strictly prohibited, unless specifically authorized by 
Swarnam Health and Lifestyle LLP.

<h3 className="margin-vertical-medium">Agreement To Be Bound</h3>

BY USING THIS WEBSITE OR ORDERING Products and Services, YOU ACKNOWLEDGE THAT YOU HAVE READ AND 
AGREE TO BE BOUND BY THIS AGREEMENT AND ALL TERMS AND CONDITIONS ON THIS WEBSITE.
General Clause:

Force Majeure: Swarnam Health and Lifestyle LLP will not be deemed in default hereunder  or held 
responsible  for any cessation,  interruption  or delay in the performance  of its obligations 
hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, 
armed conflict, labor strike, lockout, or boycott.
Cessation of Operation: Swarnam Health and Lifestyle LLP may at any time, in its sole discretion 
and without advance notice to you, cease operation of the Website and distribution of the Products 
and Services.
Entire  Agreement:  This  Agreement  comprises  the  entire agreement between you and Swarnam 
Health and Lifestyle LLP and supersedes any prior agreements pertaining to the subject matter 
contained herein.
Governing Law:

This website is controlled by Swarnam Health and Lifestyle LLP from our offices located in the 
state of Tamil Nadu, India. It can be accessed by most countries around the world. As each country 
has laws that may diPer from those of Tamil Nadu, India, by accessing our website, you agree that 
the statutes and laws of Tamil Nadu, India, without regard to its conflict of law principles to the 
contrary and the United Nations Convention on the International Sales of Goods, will apply to all 
matters relating to the use of this website and the purchase of any Products and Services through 
this site.

<h3 className="margin-vertical-medium">Effect of Waiver:</h3>

The failure of Swarnam Health and Lifestyle LLP to exercise or enforce any right or provision of 
this Agreement will not constitute a waiver of such right or provision. If any provision of this 
Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless 
agree that the court should endeavor to give ePect to the parties' intentions as reflected in the 
provision and the other provisions of this Agreement remain in full force and ePect.
Governing Law/Jurisdiction:

This Website originates from the Chennai, Tamil Nadu, India. This Agreement will be governed by the 
laws of the State of Tamil Nadu, India. It can be accessed by most countries around the world. As 
each country has laws that may diPer from those of Tamil Nadu, India without regard to its conflict 
of law principles to the contrary. Neither you nor Swarnam Health and Lifestyle LLP will 
commenceTamil Nadu), India. This Agreement will be governed by the laws of the State of Tamil Nadu, 
India. It can be accessed by most countries around the world. As each country has laws that may 
diher from those of Tamil Nadu, India without regard to its conflict of law principles to the 
contrary. Neither you nor or prosecute any suit, proceeding or claim to enforce the provisions of 
this Agreement, to recover damages for breach of or default of this Agreement, or otherwise arising 
under or by reason of this Agreement, other than in courts located in State of Tamil Nadu, India. 
It can be accessed by most countries around the world. As each country has laws that may diPer from 
those of Tamil Nadu, India. By using this Website or ordering Products and Services, you consent to 
the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim 
arising under or by reason of this Agreement. You hereby waive any right to trial by jury arising 
out of this Agreement and any related documents.

This website is controlled by Swarnam Health and Lifestyle LLP from our ohices located in the state 
of Tamil Nadu, India. It can be accessed by most countries around the world. As each country has 
laws that may diPer from those of Tamil Nadu, India, by accessing our website, you agree that the 
statutes and laws of Tamil Nadu, India without regard to the conflict of laws and the United 
Nations Convention on the International Sales of Goods, will apply to all matters relating to the 
use of this website and the purchase of any Products and Services through this site.
Furthermore, any action to enforce this User Agreement shall be brought in the courts having 
jurisdiction over such issue, located in Tamil Nadu, India. You hereby agree to judgement passed by 
such courts and waive any right to jurisdictional, venue, or inconvenient forum objections to such 
courts.

<h3 className="margin-vertical-medium">Statute of Limitation:</h3>

You agree that regardless of any statute or law to the contrary, any claim or cause of action 
arising out of or related to use of the Website or Products and Services or this Agreement must be 
filed within one (1) year after such claim or cause of action arose else be forever barred.
Waiver of Class Action Rights:

BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIM 
WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING 
OUT OF OR RELATING TO OR IN CONNECTION WITH  THIS  AGREEMENT  MUST  BE  ASSERTED INDIVIDUALLY.

<h3 className="margin-vertical-medium">Termination:</h3>


Swarnam Health and Lifestyle LLP reserves the right to terminate your access to the Website if it 
reasonably believes, in its sole discretion, that you have breached any of the terms and conditions 
of this Agreement. Following termination, you will not be permitted to use the Website and Swarnam 
Health and Lifestyle LLP may, in its sole discretion and without advance notice to you, cancel any 
outstanding orders for Products and Services. If your access to the Website is terminated, Swarnam 
Health and Lifestyle LLP reserves the right to exercise whatever means it deems necessary to 
prevent unauthorized access of the Website. This Agreement will survive indefinitely unless and 
until Swarnam Health and Lifestyle LLP chooses, in its sole discretion and without advance notice 
to you, to terminate it.
Domestic Use:

Swarnam Health and Lifestyle LLP makes no representation that the Website or Products and Services 
are appropriate or available for use in locations outside India. Users who access the Website from 
outside India do so at their own risk and initiative and must bear all responsibility for 
compliance  with any applicable local laws.
Cancellation And Refund:

Cancellation of order is not possible once the payment has been made. No refunds will be given 
except in the event of cancellation or non-performance of service by Swarnam Health and Lifestyle 
LLP.

<h3 className="margin-vertical-medium">Guarantee:</h3>

Unless otherwise expressed, Swarnam Health and Lifestyle LLP & www.apoIlomadipakkam.com expressly 
disclaims all warranties and conditions of any kind, whether express or implied, including, but not 
limited to the implied warranties and conditions of merchantability, fitness of content / Products 
and Services for a particular purpose and non-infringement.
Assignment:

You may not assign your rights and obligations under this Agreement to anyone. Swarnam Health and 
Lifestyle LLP may assign its rights and obligations under this Agreement in its sole discretion and 
without advance notice to you.
BY USING THIS WEBSITE OR ORDERING Products and Services FROM THIS WEBSITE YOU AGREE TO BE BOUND BY 
ALL OF THE TERMS  AND CONDITIONS  OF THIS AGREEMENT.
Contact Information:

If you have any questions or comments about these our Terms of Service as outlined above, you can 
contact us at:
Swarnam Health and Lifestyle LLP
74,  1st  floor,  Medavakkam  Main  Rd,Bharat  Nagar, Madipakkam,Chennai,Tamil Nadu,600091 
cs@swarnamhll.com
            </p></div>
          </div>
          <div>
            <Footer/>
          </div>
        </div>
      </Route>
    </Switch>
    </Router>
  );
}

export default App;
