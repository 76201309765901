import React from "react";
import Button from "../button/Button";
import success from "../../assets/images/PaymentSuccessful.svg";
import failed from "../../assets/images/PaymentFailed.svg";

export default function PaymentStatus(props) {
  return (
    <>
      <main className="inherit-parent-height background-color-white inherit-parent-width flex-place-children-page-center">
        <div className=" flex-place-children-page-center ">
          <div className=" padding-bottom-large">
            {props.type === "success" ? <img src={success} alt="success" /> : <img src={failed} alt="failed" />}
          </div>
          <div className=" padding-bottom-large">
            <div>{props.type === "success" ? "Registration successful!" : "Registration failed!"}</div>
          </div>
          <div>
            <div className=" padding-bottom-larger">
              {props.type === "success" ? (
                <>
                  <div className=" text-align-center font-size-large">Thank you for registering with us </div>
                  <div className="font-size-large"> Please visit the clinic for further instructions</div>
                </>
              ) : (
                <>
                  <div className=" text-align-center font-size-large">Registration was not successful. Please </div>
                  <div className="text-align-center font-size-large"> try again</div>
                </>
              )}
            </div>
          </div>
          {props.type === "success" ? (
            <Button
              data-cy="register-button"
              type="submit"
              boxShadow={false}
              className="max-width-150px margin-bottom-large "
              text="Okay"
              buttonVariant="filled"
              onClick={props.onClick}
            />
          ) : (
            <Button
              data-cy="register-button"
              type="submit"
              boxShadow={false}
              className="max-width-150px margin-bottom-large "
              text="Take me back"
              buttonVariant="filled"
              onClick={props.onClick}
            />
          )}
        </div>
      </main>
    </>
  );
}
