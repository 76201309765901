export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://asia-south1-acm-campaigns.cloudfunctions.net/"
      : (process.env.REACT_APP_STAGING === "production" ? "https://asia-south1-acm-campaigns-production.cloudfunctions.net/" : "http://localhost:5001/acm-campaigns/asia-south1/")
};

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", {weekday: 'long'}) +
      " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject
        .toLocaleString("en-IN", {
          hour: "numeric" && "2-digit",
          minute: "numeric" && "2-digit",
          hour12: true
        })
        .toUpperCase() + " ";
    return date;
  }
}
