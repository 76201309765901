export const isValidObject = (object) => {
  if (Array.isArray(object)) return false;
  return object !== null && object !== undefined && typeof object === "object" && Object.keys(object).length > 0;
};

export const isValidArray = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export const isValidString = (text) => {
  return typeof text === "string" && text.trim().length > 0;
};

// [0-9] or with dot(.) pureNumber
export const isPureNumber = (num) => {
  const pureNumRegX = new RegExp(/^[0-9]*\.?[0-9]*$/);
  return pureNumRegX.test(num);
};
