import React from "react";
import "../../index.css";
import "./Footer.css";
import email from "../../assets/icons/Email.svg";
import location from "../../assets/images/Location.svg";
import greyLogo from "../../assets/images/ApolloLogoGrey.svg";
import map from "../../assets/images/map.png";
import { useHistory } from "react-router-dom";

function Footer() {
  const history = useHistory();
  const Footer = () => {
    return (
      <footer className="background-color-light-grey border-top inherit-parent-width adaptive-height-footer footer-margin-auto-o">
        <div className="adaptive-padding-footer">
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%"}} className="padding-bottom-smaller text-align-center font-size-smaller">
            <img className="padding-bottom-small" data-cy="footer-phone-icon" draggable="false" src={greyLogo} alt="" />
            <div style={{fontWeight:"600"}} className=" text-align-center adaptive-padding-p1 font-size-smaller">Swarnam Health & Lifestyle LLP</div>
            <div className="font-size-medium margin-top-medium" style={{display: "flex", alignItems:"center"}}>
              <div onClick={() => {history.push("/about"); window.scrollTo(0, 0);}} className="margin-horizontal-medium cursor-pointer">About</div>
              <div style={{height: "20px", backgroundColor: "#e5e5e5", width:'2px'}}></div>
              <div onClick={() => {history.push("/contact"); window.scrollTo(0, 0);}} className="margin-horizontal-medium cursor-pointer">Contact</div>
              <div style={{height: "20px", backgroundColor: "#e5e5e5", width:'2px'}}></div>
              <div onClick={() => {history.push("/terms"); window.scrollTo(0, 0);}} className="margin-horizontal-medium cursor-pointer">Terms</div>
              <div style={{height: "20px", backgroundColor: "#e5e5e5", width:'2px'}}></div>
              <div onClick={() => {history.push("/privacy"); window.scrollTo(0, 0);}}v className="margin-horizontal-medium cursor-pointer">Privacy</div>
            </div>
          </div>
          <div style={{fontWeight:"600"}} className="font-size-smaller">
            <div className="padding-default margin-top-default padding-left-default" style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
              <div className="padding-right-medium">
                <a href="mailto:madipakkam@apolloclinic.com" style={{display: "flex", alignItems:"center"}}>
                  <img style={{height:"14px"}} className="padding-right-default" data-cy="footer-email-icon" draggable="false" src={email} alt="" /> 
                  <span>madipakkam@apolloclinic.com</span>
                </a>
                <br/>
                <br/>
                <div style={{display: "flex", alignItems:"center"}}>
                  <img className="padding-right-default" style={{height:"18px"}} draggable="false" src={location} alt="" />
                  <span style={{cursor: "pointer"}} className="padding-right-default">74, 1st floor, Medavakkam Main Rd, <br/> above Singapore Shop, Bharat Nagar, <br/> Madipakkam, Chennai, TN-600091</span>
                </div>
              </div>
              <a href="https://www.google.com/maps/place/Apollo+Clinic+Madipakkam/@12.9636947,80.1875591,15z/data=!4m5!3m4!1s0x0:0x42d5003d620f5d92!8m2!3d12.9636947!4d80.1875591">
                <img style={{height:"auto", cursor: "pointer", width:"175px", borderRadius: "4px"}} className="padding-right-small" draggable="false" src={map} alt="" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  };
  return <Footer />;
}

export default Footer;
