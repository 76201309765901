import React, { useState, useEffect } from "react";
import "./LandingScreen.css";
import CampaignCard from "../../components/CampaignCard/CampaignCard";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { createOrder } from "../../services/api";
import { isValidObject } from "../../utils/validators";
import banner from "../../assets/images/Banner.webp";
import banner_web from "../../assets/images/Banner_web.webp";

export default function LandingScreen(props) {
  const [registerLoading, setRegisterLoading] = useState(false);
  const [razorPayData, setRazorPayData] = useState({});
  const [clear, setClear] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    if (razorPayData && isValidObject(razorPayData)) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_ID,
        amount: razorPayData.amount, //  = INR 1
        name: "Apollo Clinic Madipakkam",
        description: "Expertise. Closer to you",
        image: "",
        order_id: razorPayData.id,
        handler: function (response) {
          props.message("Registration Successful");
          props.type("success");
          props.payment("success");
          setRazorPayData({});
          setClear(true);
        },
        prefill: {
          name: razorPayData.name,
          contact: razorPayData.phoneNumber
        },
        notes: {
          address: "some address"
        },
        theme: {
          color: "#007c9e",
          hide_topbar: false
        }
      };

      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        props.message("Payment Failed, Please try again later");
        props.payment("failed");
        setRazorPayData({});
        setClear(true);
      });
      rzp1.open(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [razorPayData]);

  const createNewOrder = async (data) => {
    setRegisterLoading(true);
    const response = await createOrder(data.campaignId, data.selectedSpecialty, data.userName, data.phoneNumber);
    if (response.success === true) {
      if (isValidObject(response.data)) {
        setRazorPayData(response.data);
      } else {
        props.message("Registration Successful");
        props.type("success");
        props.payment("success");
      }
    }
    setRegisterLoading(false);
  };

  return (
    <>
      <div className="padding-left-240px padding-right-240px">
        <Header />
        <div className="margin-top-64px font-family-gilroy-regular flex-center-children-vertically web-margin-left-large padding-top-larger padding-bottom-larger display-flex font-color-secondary font-size-small">
        </div>
        <img alt="banner" src={window.visualViewport.width >= 600 ? banner : banner_web} style={{width:"100%"}} />
        <div className="adaptive-padding-bottom">
          {props.loading === false ? (
            props.campaigns &&
            Object.values(props.campaigns) &&
            Object.values(props.campaigns).map((data, index) => (
              <div className="margin-bottom-larger" key={index}>
                <CampaignCard
                  title={data?.title}
                  description={data?.description}
                  src={data?.src}
                  startsAt={data?.startsAt}
                  endsAt={data?.endsAt}
                  cardData={data ? data : null}
                  windowInnerWidth={props?.windowInnerWidth}
                  registerButtonLoading={registerLoading}
                  createOrder={(data) => {
                    createNewOrder(data);
                  }}
                  clearData={clear}
                  clearDataNew={setClear}
                />
              </div>
            ))
          ) : (
            <CampaignCardSuspense />
          )}
        </div>
      </div>
      <div>{props.campaigns && <Footer />}</div>
    </>
  );
}

const CampaignCardSuspense = () => {
  return [...Array(3).keys()].map((index) => (
    <div
      className="background-white outline-plain adaptive-border-radius inherit-parent-width padding-left-large padding-right-large margin-bottom-larger"
      key={index}
    >
      <div className="border-bottom">
        <div className="display-flex padding-bottom-default">
          <div className=" width-30percentage">
            <div className="shimmer padding-small width-114px-height-80px margin-vertical-large border-radius-default" />
          </div>
          <div className="width-70-percent">
            <div className="padding-left-larger inherit-parent-width ">
              <div
                className="font-family-gilroy-medium width-150px padding-top-larger font-size-large font-color-secondary"
                data-cy="campaign-card-title"
              >
                <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
                <div className="display-flex">
                  <div className="shimmer padding-small margin-top-small width-60px margin-default border-radius-default " />
                  <div className="shimmer padding-small margin-left-default margin-top-small width-60px margin-default border-radius-default" />
                </div>
              </div>
              <>
                <div className="padding-top-large inherit-parent-width flex-direction-column line-height-20px work-spacing-1px padding-bottom-large font-size-large">
                  <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
                  <div className="display-flex">
                    <div className="shimmer padding-small margin-top-large width-300px margin-default border-radius-default width-30percentage" />
                    <div className="shimmer padding-small width-60px margin-default margin-top-large border-radius-default" />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="padding-bottom-larger padding-top-larger adaptive-flex-direction inherit-parent-width">
        <div className="padding-top-large web-width-60-percent web-padding-right-larger padding-bottom-larger text-align-center flex-justify-content-space-evenly adaptive-display-gird ">
          {[...Array(8).keys()].map((index) => (
            <div className="shimmer padding-small width-150px height-48px margin-vertical-large border-radius-default" key={index}>
              <div className="padding-default margin-large border-radius-default" />
              <div className="padding-default margin-large border-radius-default" />
              <div className="padding-default margin-large border-radius-default" />
            </div>
          ))}
        </div>
        <div className="web-width-40-percent web-padding-left-larger flex-center-children-horizontally adaptive-border-registrationForm adaptive-padding-registrationForm">
          <div className="flex-center-vertically-direction-column width-300px padding-left-default padding-right-larger">
            {[...Array(3).keys()].map((index) => (
              <div className=" inherit-parent-width" key={index}>
                <div className="shimmer padding-small height-48px margin-vertical-large border-radius-default">
                  <div className="padding-default margin-large border-radius-default" />
                </div>
              </div>
            ))}
            <div className="shimmer width-150px padding-small height-48px margin-vertical-large border-radius-default">
              <div className="padding-default width-150px margin-large border-radius-default" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};
