import React from "react";
// import filter from "../../assets/icons/filter.svg";
import "./Button.css";
function Button(props) {
  //filled button class
  const filledButtonClassnames =
    props.buttonVariant === "filled"
      ? "inherit-parent-width padding-vertical-medium font-color-white cursor-pointer filled-button-background  font-family-gilroy-bold border-radius-default font-size-medium"
      : null;

  //filled red button class
  const filledRedButtonClassnames =
    props.buttonVariant === "filled-red"
      ? "inherit-parent-width padding-vertical-medium font-color-white filled-red-button-background bordered-button-red-background font-family-gilroy-bold border-radius-default font-size-medium"
      : null;

  //bordered button class
  const borderedBlackButtonClassnames =
    props.buttonVariant === "bordered-black"
      ? "inherit-parent-width padding-vertical-medium font-color-tertiary background-white bordered-button-black-background font-family-gilroy-medium border-radius-default font-size-medium"
      : null;

  //bordered button class
  const borderedButtonClassnames =
    props.buttonVariant === "bordered"
      ? "inherit-parent-width padding-vertical-medium font-color-secondary background-white font-family-gilroy-medium border-radius-default font-size-medium"
      : null;

  const disabledButtonClass =
    "disabled-button-background inherit-parent-width padding-vertical-medium font-color-white font-family-gilroy-bold border-radius-default inherit-parent-width font-size-medium";

  //button class switch
  const className =
    props.buttonVariant === "filled"
      ? filledButtonClassnames
      : props.buttonVariant === "filled-red"
      ? filledRedButtonClassnames
      : props.buttonVariant === "bordered-black"
      ? borderedBlackButtonClassnames
      : props.buttonVariant === "bordered"
      ? borderedButtonClassnames
      : null;
  return (
    <>
      {/* {props.buttonCategory === "floating" ? (
        <button
          data-cy={props["data-cy"] ? props["data-cy"] : "floating-button"}
          onClick={props.onClick}
          className={`position-fixed button-size box-shadow-default background-white border-radius-16px flex-place-children-page-center flex-place-children-page-center z-index-1 ${
            props.position === "bottom-right" ? "bottom-right" : "top-right"
          }`}
        >
          <img
            src={props.src ? props.src : filter}
            alt={`${props.src}-img`}
            className={`${props.iconClassName}`}
            width="auto"
            draggable="false"
          />
        </button>
      ) : ( */}
      <button
        data-cy={props["data-cy"]}
        id={props.id}
        type={props.type}
        className={
          props.disabled === true
            ? `${disabledButtonClass} ${props.className}`
            : `cursor-pointer ${className}
              ${props.boxShadow === false ? "box-shadow-none" : "box-shadow-default"} ${props.border === false ? "" : "bordered-button-background"}
              ${props.className ? props.className : ``}`
        }
        onClick={props.onClick}
        disabled={props.disabled || props.loading}
      >
        {props.loading !== true ? (
          props.text
        ) : (
          <div className="flex-place-children-page-center padding-vertical-small" data-cy="button-loading">
            <div className={`${props.disabled ? "dot-pulse-dark" : "dot-pulse"}`} />
          </div>
        )}
      </button>
      {/* )} */}
    </>
  );
}

export default Button;
